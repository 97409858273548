<template>
  <div>
    <div v-if="openDetails" class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <toolbar :toolbarName="dynamicToolbarName" :openDetails="openDetails"/>

    <form-dialog :dialog="formDialog" :formName="dynamicFormName">
      <v-form ref="formCultures">
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Nome da Cultura"/>
            <simple-text-field v-model="payload.name"
                              required
                              placeholder="Nome da Cultura"/>
          </v-col>

          <v-col cols="12" md="2" sm="6">
            <custom-label label="Status"/>
            <simple-select
              v-model="payload.status"
              :items="statusType"
              itemText="text"
              itemValue="value"
              class="mr-2"
              placeholder="Status"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
            />
          </v-col>
        </v-row>
      </v-form>
    </form-dialog>

    <search-bar v-if="!openDetails" v-model="search" @externalFiltersClear="clearFilters" :noSecondaryInput="false">
      <simple-select
          v-model="isActiveSearch"
          :items="statusType"
          @input="filterByStatus()"
          itemText="text"
          itemValue="value"
          class="mr-2"
          placeholder="Status"
          height="0"
          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
      />
    </search-bar>

    <row-details v-if="openDetails">
      <v-row align="center">
        <v-col class="d-flex justify-start">
          <span class="font-weight-bold">Detalhes</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end">
          <v-icon @click="edit()"> mdi-pencil</v-icon>
        </v-col>
      </v-row>

      <v-row>
        <col-details column="4" name="Nome da Cultura" :item="payload.name"/>
        <col-details column="4" name="Status" :item="getStatus(payload.status)"/>
      </v-row>
    </row-details>

    <data-table :headers="headers"
                :fetching="fetching"
                :data="data"
                :search="search.text"
                :objectName="objectName"
                @loadAgain="loadAgain"
                v-else
    >
      <template v-slot:[`item.status`]="{ item }">
        {{ getStatus(item.status) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon style="color:#707070;" class="ml-4" small @click="view(item)">
          mdi-information-outline
        </v-icon>

        <v-icon class="ml-4" small @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <confirm-destroy-dialog
      :title="payload.name"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
import Toolbar from "@/components/crud/Toolbar";
import FormDialog from "@/components/crud/FormDialog";
import SearchBar from "@/components/layouts/SearchBar.vue";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
import DataTable from "@/components/data-table/DataTable";
import SimpleTextField from "@/components/inputs/SimpleTextField.vue";
import CustomLabel from "@/components/texts/CustomLabel";
import RowDetails from "@/components/crud/Details";
import ColDetails from "@/components/crud/DetailsItem";
import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";

import Cultures from "@/domain/cultures/cultures";

export default {
  name: "RegisteredCultures",
  components: {
    Toolbar,
    FormDialog,
    SearchBar,
    SimpleSelect,
    DataTable,
    SimpleTextField,
    CustomLabel,
    RowDetails,
    ColDetails,
    ConfirmDestroyDialog
  },
  data() {
    return {
      objectName: "culturas",
      toolbarName: "Lista de Culturas",
      formName: "Adicionar Cultura",
      
      openDetails: false,
      formDialog: false,

      fetching: true,
      search: {},
      isActiveSearch: "",
      data: [],

      statusType: [
        {text: "Ativo", value: 1},
        {text: "Inativo", value: 0}
      ],

      headers: [
        {text: 'ID', align: 'start', value: 'id'},
        {text: 'Cultura', value: 'name'},
        {text: 'Status', value: 'status',
          filter: value => {
            if (this.isActiveSearch === "") return true;
            return value === this.search.is_active;
          }
        },
        {text: 'Detalhes', value: 'actions', sortable: false},
      ],

      cultureService: null,
      payload: {},
      showConfirmDestroyDialog: false
    };
  },
  computed: {
    dynamicToolbarName() {
      if (this.openDetails) {
        return this.toolbarName + " / Detalhes";
      }

      return this.toolbarName;
    },
    dynamicFormName() {
      if (this.openDetails) {
        return "Editar Cultura";
      }

      return this.formName;
    },
  },
  methods: {
    getCultures() {
      let loader = this.$loading.show();

      this.fetching = true;
      this.data     = [];

      this.cultureService.list().then(result => {
        result.data.map(item => {
          this.data.push(item);
        });
        loader.hide();
      })
      .catch(({response}) => {
        loader.hide();
        const {error} = response.data;
        this.$toasted.show(error, {
          type: 'error'
        });
      });

      this.fetching = false;
    },

    getStatus(status) {
      if (status === 1) return "Ativo";
      return "Inativo";
    },

    initialize() {
      this.cultureService = Cultures;
      this.payload = this.cultureService.newData();

      this.getCultures();
    },

    view(item) {
      this.payload = Object.assign({}, item);
      this.openDetails = true;
    },

    edit() {
      this.formDialog = true;
    },

    deleteItem(item) {
      this.payload = Object.assign({}, item);
      this.showConfirmDestroyDialog = true;
    },

    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },

    create() {
      this.payload = {};
      this.payload.status = 1;
      this.formDialog = true;
    },

    closeFormDialog() {      
      this.formDialog = false;
    },

    closeView() {
      this.openDetails = false;
      this.initialize();
    },

    save() {
      if (!this.$refs.formCultures.validate()) {
        return;
      }

      let loader = this.$loading.show();

      if (!this.openDetails) {
        this.cultureService.create(this.payload)
          .then(() => {
            loader.hide();
            this.closeFormDialog();
            this.initialize();
            this.$toasted.show("Cultura cadastrada com sucesso.", {
              type: 'success'
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: 'error'
            });
          });
      } else {
        this.cultureService.edit(this.payload)
          .then(() => {
            loader.hide();
            this.closeFormDialog();
            this.$toasted.show("Cultura atualizada com sucesso.", {
              type: "success",
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
      }
    },

    async destroy() {
      const loader = this.$loading.show();

      await this.cultureService.delete(this.payload).then(() => {
        loader.hide();
        this.getCultures();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Cultura removida com sucesso.", {
          type: "success",
        });
      })
      .catch(() => {
        loader.hide();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Cultura não foi removida.", {
          type: 'error'
        });
      });
    },

    filterByStatus() {
      this.search.is_active = this.isActiveSearch;
    },

    clearFilters() {
      this.search = {};
      this.isActiveSearch = "";
    },

    loadAgain() {
      this.initialize();
    }
  },

  beforeMount() {
    this.initialize();
  },
}
</script>

<style scoped>
.custom-container {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

p {
  color: #000000;
}

.section-title {
  font-size: 22px;
}
</style>
